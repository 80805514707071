import React,{Component} from 'react';

/// modal
import { Modal } from "react-bootstrap";

class SubscriptionBox extends Component{
	/// model box
	state = {
		// initial state
		show: true,
	}

	handleClose = () => {
		this.setState({ show: false });
	};
	handleShow = () => {
		this.setState({ show: true });
	};
	render(){
		return(
			<div className="container">
							
			<button className="btn btn-primary" onClick={this.handleShow} type="button" data-toggle="modal" data-target="#exampleModal">Open Modal Box</button>
			{/*  InquiryModal  */}		
								<Modal show={this.state.show} onHide={this.handleClose} className=" fade inquiry-modal " id="exampleModal" tabindex="-1" >
									<div className="modal-header">
										<h5 className="modal-title" id="exampleModalLongTitle">Register now for a virtual project tour with our sales expert.</h5>
										<button type="button" className="close" onClick={this.handleClose} data-dismiss="modal" aria-label="Close">
										  <span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div className="modal-body">
										<div className="dzFormMsg"></div>
										<form action="script/contact.php" className="dzForm" method="post">
											<input type="hidden" value="Appointment" name="dzToDo" />
											<input type="hidden" value="0" name="reCaptchaEnable" />
											<div className="form-group">
												<input type="text" name="dzName" required className="form-control" placeholder="Nombre" />
											</div>
											<div className="form-group">
												<input type="text" name="dzOther[Phone]" required className="form-control" placeholder="Teléfono" />
											</div>
											<div className="form-group">
												<input type="email" name="dzEmail" className="form-control" required placeholder="Correo electrónico" />
											</div>
											<button name="submit" type="submit" value="Submit" className="btn btn-primary btn-block">ENVIAR</button>
										</form>
									</div>
								</Modal>
								</div>

		)
	}
}
export default SubscriptionBox;
